import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import goTo from 'vuetify/lib/services/goto'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import(/* webpackChunkName: "about-us" */ '../views/AboutView.vue')
  },
  {
    path: '/simulasi-kredit',
    name: 'simulasi-kredit',
    component: () => import(/* webpackChunkName: "simulasi-kredit" */ '../views/SimulasiKredit.vue')
  },
  {
    path: '/lokasi-cabang',
    name: 'lokasi-cabang',
    component: () => import(/* webpackChunkName: "lokasi-cabang" */ '../views/LokasiNetwork.vue')
  },
  {
    path: '/karir',
    name: 'karir',
    component: () => import(/* webpackChunkName: "karir" */ '../views/Karir.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '../components/NotFound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
})

export default router
