<template>
  <v-app>
    <v-app-bar
      app
      dark
      src="/img/header_merah.jpg"
      v-if="isHome || $vuetify.breakpoint.smAndUp"
    >
      <a href="/">
        <v-img
          alt="Warna Logo"
          class="shrink mr-2"
          contain
          src="/img/logo_atas.png"
          transition="scale-transition"
        />
      </a>

      <v-spacer></v-spacer>

      <div v-if="$vuetify.breakpoint.smAndUp">
        <v-btn text class="mx-1 text-capitalize" href="/"> Beranda </v-btn>

        <v-btn
          text
          class="mx-1 text-capitalize"
          v-for="(item, i) in menus"
          :key="i"
          :to="item.to"
        >
          {{ item.text }}
        </v-btn>
      </div>

      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="$vuetify.breakpoint.xs"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app>
      <v-list nav>
        <v-list-item href="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Beranda </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item-group v-model="selectedMenu" color="red">
          <v-list-item v-for="(item, i) in menus" :key="i" :to="item.to">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <alert />

    <v-main>
      <v-slide-y-transition>
        <router-view />
      </v-slide-y-transition>
    </v-main>

    <v-btn
      fixed
      bottom
      right
      fab
      large
      color="green"
      dark
      href="https://wa.me/+6282110082930?text=Halo Saya ingin tahu tentang Warung Dana"
      target="_blank"
    >
      <v-icon large>mdi-whatsapp</v-icon>
    </v-btn>

    <v-row align="center" justify="center" class="red mt-4">
      <v-col cols="12" sm="4" align="center">
        <v-img
          alt="Warna Logo"
          class="shrink mr-2"
          contain
          src="/img/warna_white.png"
          transition="scale-transition"
          width="250"
          height="150"
        />
      </v-col>

      <v-col cols="12" sm="4" align="center">
        <div class="d-flex flex-wrap justify-center">
          <div class="flex-column">
            <router-link
              v-for="(item, i) in menus"
              :key="i"
              :to="item.to"
              class="white--text mx-2"
            >
              {{ item.text }}
            </router-link>
          </div>
        </div>
      </v-col>

      <v-col cols="12" sm="4" align="center">
        <div class="d-flex justify-center align-center">
          <div class="white--text">Ikuti kami:</div>

          <div class="mx-2"></div>

          <v-btn
            href="https://instagram.com/warungdana.official"
            target="_blank"
            fab
            small
            color="pink"
            dark
          >
            <v-icon>mdi-instagram</v-icon>
          </v-btn>

          <div class="mx-2"></div>

          <v-btn
            href="https://tiktok.com/@warungdana_official"
            target="_blank"
            fab
            small
            color="white"
            dark
          >
            <v-img src="/img/tiktok.png" contain />
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-footer padless>
      <v-col class="red white--text text-center" cols="12">
        Copyright © {{ new Date().getFullYear() }} Warung Dana | Powered by
        Warung Dana
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "App",
  components: {
    Alert: () =>
      import(/* webpackChunkName: "alert" */ "@/components/Alert.vue"),
  },
  data: () => ({
    drawer: false,
    selectedMenu: 1,
    menus: [
      { text: "Lokasi Cabang", icon: "mdi-web mdi-spin", to: "/lokasi-cabang" },
      { text: "Karir", icon: "mdi-account-search", to: "/karir" },
      { text: "About Us", icon: "mdi-exclamation-thick", to: "/about-us" },
    ],
    fab: false,
  }),
  computed: {
    isHome() {
      return this.$route.path === "/";
    },
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
    }),
  },
  mounted() {
    setTimeout(() => {
      AOS.init();
    }, 2000);
  },
};
</script>

<style>
a:link {
  text-decoration: none;
}
</style>