<template>
  <div>
    <v-carousel cycle hide-delimiter-background show-arrows-on-hover class="mb-3">
      <v-carousel-item v-for="item in banners" :key="item.id" :src="getImage(item.banner_img)">
        <!-- <img
          src="/img/logo-warna-png.png"
          alt="warna"
          width="350"
          height="250"
          class="logo"
        />

        <div class="transbox">
          <div class="text-h6 font-weight-bold">{{ item.text }}</div>
          <div class="text-h3 font-weight-bold">WARUNG DANA solusinya!!!</div>
          <div class="text-h6 font-weight-bold">
            Mewujudkan Seluruh Kebutuhan Anda
          </div>
        </div> -->
      </v-carousel-item>
    </v-carousel>

    <div style="max-width: 1240px" class="mx-auto">
      <simulasi />

      <br />

      <div class="text-h4 text-center font-weight-bold">Keunggulan Kami</div>

      <v-row justify="space-around" class="my-3">
        <v-col cols="6" sm="2" class="d-flex flex-column" v-for="(item, i) in pros" :key="i">
          <img :src="item.src" :alt="item.src" />

          <div class="text-center text-h6">{{ item.text }}</div>
        </v-col>
      </v-row>

      <div data-aos="fade-up" data-aos-duration="2000">
        <v-card rounded="xl" elevation="14" class="pa-2">
          <v-card-title class="d-flex justify-center text-h5 font-weight-bold">
            Proses Cepat Warung Dana
          </v-card-title>

          <v-card-subtitle>
            <div class="text-h6 text-center">Cukup Bawa BPKB Anda</div>
          </v-card-subtitle>

          <v-card-text>
            <div style="
                    background-image: url('/img/background_proses_warungdana.png');
                    background-position: center;
                    background-size: cover;
                    border-radius: 25px;
                  ">
              <v-row justify="space-around" align="center" class="my-2">
                <v-col cols="6" sm="2" class="d-flex flex-column">
                  <img src="/img/icon_rencanakan.png" alt="rencanakan" />

                  <div class="text-center text-h6 white--text">Rencanakan</div>
                </v-col>

                <v-col cols="6" sm="2" v-if="$vuetify.breakpoint.smAndUp">
                  <img src="/img/icon_panah.png" alt="panah" />
                </v-col>

                <v-col cols="6" sm="2" class="d-flex flex-column">
                  <img src="/img/fix2.png" alt="hubungi" />

                  <div class="text-center text-h6 white--text">
                    Hubungi Kami
                  </div>
                </v-col>

                <v-col cols="6" sm="2" v-if="$vuetify.breakpoint.smAndUp">
                  <img src="/img/icon_panah.png" alt="panah" />
                </v-col>

                <v-col cols="6" sm="2" class="d-flex flex-column">
                  <img src="/img/icon_terima_dana.png" alt="terimadana" />

                  <div class="text-center text-h6 white--text">Terima Dana</div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </div>

      <br />

      <div data-aos="fade-up" data-aos-duration="2000">
        <v-card rounded="xl" elevation="14">
          <v-row dense align="center" class="my-4">
            <v-col cols="12" sm="6">
              <v-card-title class="font-weight-bold">
                Tentang Warung Dana
              </v-card-title>

              <v-card-text class="text-justify">
                <div>
                  Warung Dana mengelola bisnis dengan produk Dana Tunai.
                </div>

                <div class="my-2"></div>

                <div>
                  Warung dana terbentuk untuk menjadi solusi keuangan bagi
                  masyarakat Indonesia yang bekerjasama dengan Mitra dan
                  Partener Bisnis terpercaya.
                </div>
              </v-card-text>
            </v-col>

            <v-col cols="12" sm="6">
              <v-container fluid>
                <iframe id="video" title="video" width="100%" height="360" :src="now_playing">
                </iframe>

                <br />

                <div class="d-flex">
                  <v-btn v-for="(item, i) in videos" :key="i" color="red" dark fab @click="selectVideo(item)"
                    class="mx-1">
                    {{ i + 1 }}
                  </v-btn>
                </div>
              </v-container>
            </v-col>
          </v-row>
        </v-card>
      </div>

      <br />

      <div data-aos="fade-up" data-aos-duration="2000">
        <v-row v-viewer="{ movable: false }" class="my-4" justify="space-around">
          <v-col cols="12" sm="4" v-for="src in images.slice(0, slice)" :key="src.id" align="center">
            <v-card rounded="xl" elevation="14">
              <v-container fluid>
                <img :src="getImage(src.gal_src)" width="100%" style="
                        object-fit: contain;
                        cursor: pointer;
                        border-radius: 25px;
                      " :alt="src" />
              </v-container>
            </v-card>
          </v-col>
        </v-row>

        <div align="center">
          <v-btn color="red" dark @click="loadMore" v-if="slice == images.length ? false : true">
            load more
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";

Vue.use(VueViewer);

export default {
  name: "HomeView",
  components: {
    Simulasi: () =>
      import(/* webpackChunkName: "simulasi" */ "@/components/Simulasi.vue"),
  },
  data: () => ({
    banners: [],
    requiredRules: [(v) => !!v || "Wajib diisi"],
    phoneRules: [
      (v) => !!v || "Nomor Handphone wajib diisi",
      (v) => v.length >= 10 || "Nomor Handphone minimal 10 digit",
      (v) => v.length <= 13 || "Nomor Handphone maksimal 13 digit",
    ],
    loading: false,
    pros: [
      {
        src: "/img/1-jam-cair.png",
        text: "30 Menit Cair",
      },
      {
        src: "/img/icon_globe.png",
        text: "14 Cabang Perwakilan",
      },
      {
        src: "/img/icon_money.png",
        text: "Plafon Pencairan Maksimal",
      },
      {
        src: "/img/icon_ojk.png",
        text: "Man Power Responsive",
      },
      {
        src: "/img/logo_thumbsup.png",
        text: "Pengalaman Lebih Dari 15 Tahun",
      },
    ],
    images: [],
    videos: [
      "https://www.youtube.com/embed/nCqrhELlzMY?autoplay=1",
      "https://www.youtube.com/embed/gbzEpv423R8?autoplay=1",
    ],
    now_playing: "https://www.youtube.com/embed/nCqrhELlzMY",
    slice: 6,
  }),
  methods: {
    async getBanner() {
      this.loading = true;

      await this.axios
        .get("display-banner", {
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          let { data } = response.data;
          this.banners = data;
          this.loading = false;
        })
        .catch((error) => {
          let responses = error.response.data;
          this.setAlert({
            status: true,
            color: "error",
            text: responses.message,
          });
        });
    },
    selectVideo(val) {
      let myVideo = document.getElementById("video");
      myVideo.src = val;
    },
    async getGallery() {
      this.loading = true;

      await this.axios
        .get("display-image", {
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          let { data } = response.data;
          this.images = data;
          this.loading = false;
        })
        .catch((error) => {
          let responses = error.response.data;
          this.setAlert({
            status: true,
            color: "error",
            text: responses.message,
          });
        });
    },
    loadMore() {
      this.slice += 3;
    },
  },
  async created() {
    await this.getBanner();
    await this.getGallery();
  },
};
</script>

<style>
.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

div.transbox {
  background-color: black;
  opacity: 0.6;
  color: white;
}
</style>
